import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { CollectionFilterList, Hero, ProductCard, StandardGridLayout } from "@global";
import { Container, DesktopContainer } from "@util/standard";
import Layout from "@shared/layout";
import { useStore } from "@state/store";
import {
  calculateGridItemBorders,
  checkCollectionProductLength,
  filterProducts,
  sortProductsByCategoryOrder,
} from "@util/helper";

interface Props extends PageProps {
  data: Query;
  pageContext: { rangeSlug: string; slug: string };
}

function CollectionTemplate({ data, pageContext: { slug } }: Props) {
  const collection = data.allSanityCollection.nodes[0];
  const products = data.allSanityProduct.nodes;
  const range = data.allSanityRange.nodes[0];

  const { categoryFilters } = useStore();

  const filteredCollections =
    range.rangeCollections &&
    range.rangeCollections.filter(collection => {
      if (collection == null) return null;
      const collectionLength = checkCollectionProductLength(collection);
      return collectionLength && collectionLength > 0;
    });

  const sortedProducts = sortProductsByCategoryOrder(collection, products);

  const filteredProducts = sortedProducts && filterProducts(sortedProducts, categoryFilters);
  const lastItem =
    filteredProducts && filteredProducts.indexOf(filteredProducts[filteredProducts.length - 1]) + 1;

  return (
    <Layout>
      <Hero title={collection.title} data={collection.heroBlock} />

      <Container width="100%" tabletFlexDirection="column">
        <DesktopContainer width="30%" tabletWidth="100%">
          {range.title && range.rangeCollections && (
            <CollectionFilterList
              title={range.title}
              rangeSlug={range.slug}
              data={filteredCollections}
              context={slug}
              collection={collection}
              products={products}
            />
          )}
        </DesktopContainer>

        <Container margin="0 2% 0 0" flexDirection="column" width="70%" tabletWidth="100%">
          {filteredProducts && (
            <StandardGridLayout
              textData={collection.collectionDescription}
              mobileChildren={
                <CollectionFilterList
                  title={range.title}
                  rangeSlug={range.slug}
                  data={range.rangeCollections}
                  context={slug}
                  collection={collection}
                  products={products}
                />
              }
            >
              {filteredProducts.map((product, index) => {
                if (product == null) return null;
                if (lastItem == null) return null;
                const { lastRowHasOne, lastRowHasTwo, lastRowHasThree, noBorderLeft } =
                  calculateGridItemBorders(index, lastItem, filteredProducts.length);

                const noBorderBottom = lastRowHasOne || lastRowHasTwo || lastRowHasThree;

                return (
                  <ProductCard
                    data={product}
                    key={product.id}
                    hasBorderLeft={!noBorderLeft}
                    hasBorderBottom={!noBorderBottom}
                  />
                );
              })}
            </StandardGridLayout>
          )}
        </Container>
      </Container>
    </Layout>
  );
}

export default CollectionTemplate;

export const query = graphql`
  query allSanityCollectionQuery($slug: String, $rangeSlug: String, $countryCode: String) {
    allSanityRange(filter: { slug: { current: { eq: $rangeSlug } } }) {
      nodes {
        ...sanityRange
      }
    }
    allSanityCollection(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityCollection
      }
    }
    allSanityProduct(
      filter: {
        categories: { elemMatch: { collectionRef: { slug: { current: { eq: $slug } } } } }
        regions: { elemMatch: { region: { countryCode: { eq: $countryCode } } } }
      }
    ) {
      nodes {
        categories {
          collectionRef {
            slug {
              current
            }
          }
        }
        ...sanityProductPreview
      }
    }
  }
`;
